<template>
  <div class="flex justify-between bg-white rounded-lg py-5 px-4 space-x-1">
    <div class="flex space-x-3 items-center flex-1">
      <component :is="icon" />
      <p
        class="font-bold text-base text-promy-purply-900"
        :class="{
          'w-3/4': title.length > 30,
        }"
      >
        {{ title }}
      </p>
    </div>
    <p
      class="font-normal text-base text-promy-purply-900 flex justify-end"
      :class="{
        'w-[4.75rem]': title.length > 30,
      }"
      v-if="isExist(value)"
    >
      {{ value }}
    </p>
    <span class="font-normal text-base text-promy-purply-900" v-else> NC </span>
  </div>
</template>

<script>
import PopulationIcon from './Icons/PopulationIcon.vue'
import DensiteIcon from './Icons/DensiteIcon.vue'
import FoyerIcon from './Icons/FoyerIcon.vue'
import MaritalStatusIcon from './Icons/MaritalStatusIcon.vue'
import MenageIcon from './Icons/MenageIcon.vue'
import TauxCommunalIcon from './Icons/TauxCommunalIcon.vue'
import TFBIcon from './Icons/TFBIcon.vue'
import Zone from './Icons/Zone.vue'
import NbrParcelles from './Icons/NbrParcelles.vue'

import helpers from '@/mixins/helpers'

export default {
  components: {
    PopulationIcon,
    DensiteIcon,
    FoyerIcon,
    MaritalStatusIcon,
    MenageIcon,
    TauxCommunalIcon,
    TFBIcon,
    Zone,
    NbrParcelles,
  },
  mixins: [helpers],
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
